import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { SectionText, SectionTitle } from '../../typography';

const TitleTextWrapperStyle = createOwnUpStyle({
  variants: {
    mediumAndDown: {
      marginTop: '30px',
      textAlign: 'center'
    }
  }
});

const TitleTextWrapper = createOwnUpComponent(OwnUpBox, TitleTextWrapperStyle);

export const TitleText = () => {
  return (
    <TitleTextWrapper>
      <SectionTitle variant="main" level={1}>
        Lender Grader
      </SectionTitle>
      <SectionText>
        Explore how any lender&apos;s rates compare to the average in your state. Shop your way to a
        better mortgage.
      </SectionText>
    </TitleTextWrapper>
  );
};
