import { validate as validateEmail } from '@rategravity/core-lib/validate-email';
import { FOG_20 } from '@rategravity/own-up-component-library';
import {
  AdornOwnUpInput,
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpText,
  OwnUpTextInput
} from '@rategravity/own-up-component-library-legacy';
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { InputsContext, PartialParameters } from '../../../context';
import { colors } from '../../../modules';

const DisclaimerTextStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  fontSize: '12px',
  lineHeight: '170%'
});

const DisclaimerText = createOwnUpComponent(OwnUpText, DisclaimerTextStyle);

const BodyWrapperStyle = createOwnUpStyle({
  marginTop: '10px',
  marginBottom: '25px'
});

const BodyWrapper = createOwnUpComponent(OwnUpBox, BodyWrapperStyle);

const ContentWrapperStyle = createOwnUpStyle({
  backgroundColor: FOG_20,
  padding: '20px 2px 0 2px',
  variants: {
    mediumAndDown: {
      padding: '20px 0'
    }
  }
});

const ContentWrapper = createOwnUpComponent(OwnUpBox, ContentWrapperStyle);

const ErrorMessageStyle = createOwnUpStyle({
  color: colors.ERROR,
  fontSize: '13px'
});

const ErrorMessage = createOwnUpComponent(OwnUpText, ErrorMessageStyle);

const ErrorMessageWrapperStyle = createOwnUpStyle({
  marginTop: '5px',
  marginBottom: '15px'
});

const ErrorMessageWrapper = createOwnUpComponent('div', ErrorMessageWrapperStyle);

const ErrorMessageBody = () => (
  <ErrorMessageWrapper>
    <ErrorMessage id="error-message" tabIndex={0}>
      Please enter a valid email address
    </ErrorMessage>
  </ErrorMessageWrapper>
);

const InputStyle = createOwnUpStyle({
  color: colors.PRIMARY,
  fontSize: '15px',
  maxWidth: 'inherit',
  variants: {
    xsmall: {
      minWidth: 'unset',
      width: '100%'
    }
  },
  '& label': {
    color: colors.PRIMARY_ALT
  }
});

const Input = createOwnUpComponent(AdornOwnUpInput, InputStyle);

const InputWrapperStyle = createOwnUpStyle({
  display: 'flex',
  alignItems: 'center',
  border: `solid 1px ${colors.GREY}`,
  borderRadius: '4px',
  height: '60px',
  marginTop: '15px',
  width: '100%',
  '.inputWithError': {
    border: `solid .5px ${colors.ERROR}`
  },
  '.ou-input-adornment-container': {
    ':focus': {
      border: `solid 1px ${colors.PRIMARY}`,
      outline: colors.PRIMARY
    }
  },
  '.ou-input-adornment-container-focused': {
    outline: 'none'
  }
});

const InputWrapper = createOwnUpComponent('div', InputWrapperStyle);

const TitleTextStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  fontSize: '17px'
});

const TitleText = createOwnUpComponent(OwnUpText, TitleTextStyle);

interface EmailAddressInput {
  email: string | undefined;
  emailError: boolean;
  onBlur: () => void;
  onFocus: () => void;
  setEmailAddress: Dispatch<SetStateAction<PartialParameters>>;
}

const EmailAddressInput = memo<EmailAddressInput>(
  ({ email, setEmailAddress, onBlur, onFocus, emailError }) => (
    <Input
      className="fs-mask"
      value={email}
      name="Enter your email address"
      error={emailError}
      inputLabel="Enter your email address"
      aria-label="Enter your email address"
      onChange={(value: string | undefined) =>
        setEmailAddress((prevState: PartialParameters) => ({
          ...prevState,
          email: value,
          mode: 'manual'
        }))
      }
      input={OwnUpTextInput}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
);

EmailAddressInput.displayName = 'EmailAddressInput';

export const EmailAddress = () => {
  const { inputs, setInputs } = useContext(InputsContext);
  const { email } = inputs;
  const [emailError, setEmailError] = useState(false);

  const checkEmailError = useCallback(() => {
    if (email) {
      if (!validateEmail(email)) {
        setEmailError(true);
      }
    }
  }, [email]);

  useEffect(() => {
    if (emailError) {
      document.getElementById('error-message')?.focus();
    }
  }, [emailError]);

  return (
    <ContentWrapper>
      <TitleText>Email my results</TitleText>
      <InputWrapper className={emailError ? 'inputWithError' : ''}>
        <EmailAddressInput
          email={email}
          setEmailAddress={setInputs}
          onBlur={checkEmailError}
          onFocus={() => setEmailError(false)}
          emailError={emailError}
        />
      </InputWrapper>
      {emailError ? <ErrorMessageBody /> : null}
      <BodyWrapper>
        <DisclaimerText>
          We&apos;ll never sell or share your email address, and you can opt-out of emails from us
          at any time. For more information, please refer to our{' '}
          <a
            href={`${process.env.GATSBY_WWW}/privacy`}
            aria-label="Own Up Privacy Policy"
            style={{ color: colors.LOGO_SHADOW }}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </DisclaimerText>
      </BodyWrapper>
    </ContentWrapper>
  );
};
