/* eslint abcsize/abcsize: ["error", {max:23}] -- tolerate internal fcns increasing component ABC */

import { useTrackingInstance } from '@rategravity/1pt-lib';
import { validate as validateEmail } from '@rategravity/core-lib/validate-email';
import { FOG_20 } from '@rategravity/own-up-component-library';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpButton,
  OwnUpText,
  ScreenSize,
  useScreenSize
} from '@rategravity/own-up-component-library-legacy';
import React, { useContext } from 'react';
import { FullWidthField } from '../components/full-width-field';
import { EmailAddress } from '../components/home/email-address';
import { MainInputs } from '../components/home/main-inputs';
import { TitleText } from '../components/home/title-text';
import { HorizontalLine } from '../components/horizontal-line';
import { Layout } from '../components/layout';
import {
  ErrorContext,
  FeedbackContext,
  InputsContext,
  LoadingContext,
  PartialParameters,
  RequestContext
} from '../context';
import { colors, getResults, isEqual, parseURL } from '../modules';

const HMDATextStyle = createOwnUpStyle({
  color: colors.NIMBUS_2,
  fontSize: '12px',
  letterSpacing: '.5px',
  lineHeight: '170%'
});

const HMDAText = createOwnUpComponent(OwnUpText, HMDATextStyle);

const HMDATextWrapperStyle = createOwnUpStyle({
  marginTop: '25px'
});

const HMDATextWrapper = createOwnUpComponent(OwnUpBox, HMDATextWrapperStyle);

const HMDA = () => (
  <HMDATextWrapper>
    <HMDAText>
      Evaluate if your lender offers fair terms by assessing publicly available information
      submitted to the Federal Government through the Home Mortgage Disclosure Act (HMDA).{' '}
      <a
        href="https://ffiec.cfpb.gov/"
        target="_blank"
        rel="noreferrer"
        style={{ color: 'inherit' }}
      >
        Learn More About HMDA.
      </a>
    </HMDAText>
  </HMDATextWrapper>
);

const submitEmailToIterable = (email: string) => {
  const emailFormData = new FormData();
  emailFormData.append('email', email);
  const request = new XMLHttpRequest();
  request.open(
    'POST',
    '//links.iterable.com/lists/publicAddSubscriberForm?publicIdString=29a3535d-8a31-4b6d-8305-d08c486c0dab'
  );
  request.send(emailFormData);
};

export const Home = () => {
  const { inputs, setInputs } = useContext(InputsContext);
  const { email } = inputs;
  const { setFeedback } = useContext(FeedbackContext);
  const { request, setRequest } = useContext(RequestContext);
  const { setError } = useContext(ErrorContext);
  const { startLoading, endLoading } = useContext(LoadingContext);
  const trackingInstance = useTrackingInstance();

  const params = new URLSearchParams(location.search.substring(1));
  const newInputs: PartialParameters = parseURL(params);

  if (
    inputs.mode !== 'manual' && // Do not reference new inputs in manual mode.
    !isEqual(newInputs, inputs) // Do not update state if there are no new defined inputs.
  ) {
    setInputs((prevState: PartialParameters) => ({
      ...prevState,
      ...newInputs
    }));

    if (newInputs.lei !== undefined) {
      // if lei is defined, state is defined
      trackingInstance.track('clickedResultsCTA-lenderGrader', { newInputs });
      trackingInstance.track('Lender Grader Results CTA Clicked', { newInputs });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getResults(newInputs, setRequest, setFeedback, setError, startLoading, endLoading, true);
    }
  }

  const screenSize = useScreenSize();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // send email to Iterable if email exists
    if (email) {
      submitEmailToIterable(email);
    }

    if (!isEqual(inputs, request)) {
      trackingInstance.track('clickedResultsCTA-lenderGrader', { newInputs });
      trackingInstance.track('Lender Grader Results CTA Clicked', { newInputs });
      await getResults(inputs, setRequest, setFeedback, setError, startLoading, endLoading, true);
    }
  };

  const setDisabled = () =>
    inputs.state === undefined ||
    inputs.lei === undefined ||
    (inputs.email !== undefined && inputs.email !== '' && !validateEmail(inputs.email));

  return (
    <Layout>
      <TitleText />
      <form onSubmit={handleSubmit}>
        <OwnUpBox style={{ padding: '20px 0' }}>
          <MainInputs />
        </OwnUpBox>
        <FullWidthField backgroundColor={FOG_20}>
          <HorizontalLine variant="main" />
        </FullWidthField>
        <EmailAddress />
        <OwnUpButton
          type="submit"
          onClick={handleSubmit}
          disabled={setDisabled()}
          variant="heroic"
          style={{
            padding: screenSize >= ScreenSize.small ? '0 60px' : 0,
            margin: 0
          }}
        >
          Grade My Lender
        </OwnUpButton>
      </form>
      <HMDA />
    </Layout>
  );
};

export default Home;
