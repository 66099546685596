import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import { colors } from '../../modules';

const HorizontalLineStyle = createOwnUpStyle({
  border: `0 none`,
  height: '1px',
  backgroundColor: colors.GREY_LINE,
  variants: {
    header: {
      margin: '50px 0px 30px',
      width: '90vw'
    },
    main: {
      backgroundColor: colors.GREY,
      maxWidth: '700px'
    }
  }
});

export const HorizontalLine = createOwnUpComponent('hr', HorizontalLineStyle);
