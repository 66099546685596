import {
  colors,
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpIcon,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import React, { useState } from 'react';
import { LoanTriad } from '../../loan-triad';
import { StateAndLender } from '../../state-lender';

const UpChevronStyle = createOwnUpStyle({
  marginLeft: '10px',
  '> svg': {
    fill: colors.BLUE,
    stroke: colors.BLUE,
    top: '.3em'
  }
});

const UpChevron = createOwnUpComponent(OwnUpIcon, UpChevronStyle);

const DownChevronStyle = createOwnUpStyle({
  marginLeft: '10px',
  '> svg': {
    bottom: '.1em',
    fill: colors.BLUE,
    stroke: colors.BLUE,
    top: 0
  }
});

const DownChevron = createOwnUpComponent(OwnUpIcon, DownChevronStyle);

const FiltersButtonStyle = createOwnUpStyle({
  backgroundColor: 'inherit',
  border: 'none',
  paddingLeft: 0,
  marginBottom: '10px'
});

const FiltersButton = createOwnUpComponent('button', FiltersButtonStyle);

const FiltersTextStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  color: colors.BLUE,
  fontSize: '17px'
});

const FiltersText = createOwnUpComponent(OwnUpText, FiltersTextStyle);

export const MainInputs = () => {
  const [showFilters, setShowFilters] = useState(false);
  const filtersText = showFilters ? 'Fewer filters' : 'More filters';
  return (
    <>
      <StateAndLender />
      <FiltersButton type="button" onClick={() => setShowFilters(!showFilters)}>
        <FiltersText variant="bold">{filtersText}</FiltersText>
        {showFilters ? <UpChevron icon="ChevronUp" /> : <DownChevron icon="ChevronDown" />}
      </FiltersButton>
      {showFilters ? <LoanTriad /> : null}
    </>
  );
};
